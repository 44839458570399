var $name="appendAsyncScriptElement",$path="app/utilities/appendAsyncScriptElement.js",$this={$name,$path,};/**
 * Create async script element and place it on page
 * @param {string} src
 */
export default function appendAsyncScriptElement(src, type = 'text/javascript') {
  const script = document.createElement('script');
  script.async = true;
  script.src = src;
  script.type = type;
  script.setAttribute('nonce', 'sgo3un1q3uu3qskh');
  const promise = new Promise(
    (resolve, reject) => {
      script.onerror = reject;
      script.onload = resolve;
    },
  );
  document.body.insertAdjacentElement('beforeend', script);
  return promise;
}
