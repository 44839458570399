var $name="shell/engine/index",$path="app/modules/shell/engine/index.js",$this={$name,$path,};import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import identity from 'lodash/identity';
import isFunction from 'lodash/isFunction';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';

import configs from 'configs/index';
import Log from '../Log';
import registry from '../registry';
import settings from '../settings';

import applyUtmParams from './applyUtmParams';
import configureGsap from './configureGsap';
import prepareSettings from './prepareSettings';
import watchAccessibility from './watchAccessibility';
import watchBreakpoints from './watchBreakpoints';
import watchDOMMutations from './watchDOMMutations';
import watchKeyboardUsage from './watchKeyboardUsage';
import watchWindowResize from './watchWindowResize';
import applyNonce from './applyNonce';

const activators = [
  applyUtmParams,
  configureGsap,
  prepareSettings,
  watchAccessibility,
  watchBreakpoints,
  watchKeyboardUsage,
  watchWindowResize,
  applyNonce,
];

let disposers;
let isActive = false;
const log = new Log($this);

function activate() {
  if (isActive) return;
  isActive = true;

  if (process.env.NODE_ENV === configs.envs.development
    || process.env.NODE_ENV === configs.envs.test) activators.push(watchDOMMutations);
  disposers = filter(
    map(
      activators,
      (activator) => activator(log),
    ),
    isFunction,
  );

  log.info('Started with settings:\n%o', mapValues(settings, identity));
}

function dispose() {
  if (!isActive) return;

  registry.dispose();
  forEach(
    disposers,
    (disposer) => disposer(),
  );
  log.info('Stopped');

  isActive = false;
}

const engine = {
  get isActive() {
    return isActive;
  },
  activate,
  dispose,
};

export default engine;
