var $name="shell/engine/applyNonce",$path="app/modules/shell/engine/applyNonce.js",$this={$name,$path,};function applyNonce() {
  document.addEventListener('DOMContentLoaded', () => {
    const spriteElements = document.querySelectorAll('.svg-loader-sprite');
    spriteElements?.forEach((spriteElement) => {
      spriteElement.style.display = 'none';
      spriteElement.setAttribute('nonce', 'sgo3un1q3uu3qskh');
    });
  });
}

export default applyNonce;
