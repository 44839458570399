var $class="se2--submit",$name="submit",$path="app/partials/submit/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

import addUtmParamsToFormData from 'app/components/utilities/addUtmParamsToFormData';
import validateFormElements from 'app/components/utilities/validateFormElements';

import toBoolean from 'app/utilities/toBoolean';

import { css, form as formConfig, messages, statuses } from 'configs';

import every from 'lodash/every';
import toggleSnackbarMessage from 'app/components/utilities/toggleSnackbarMessage';

const customDropzoneSelector = '.custom-dropzone';
const formBlockDisabledClassName = 'form-block-disabled';
const loaderIconClassName = 'se2-icon-loader';

export default shell.registerPartial($this, ({ addEventListener, mount, publishMessage }) => {
  const toggleFormDisabledState = (form, submitButton, isLoading = true) => {
    form.classList.toggle(formBlockDisabledClassName, isLoading);
    submitButton.classList.toggle(loaderIconClassName, isLoading);
  };

  const validateCourseFinderForm = (form) => {
    if (!toBoolean(form.dataset.queryStringShouldBeChecked)) return true;
    const isValid = every(
      Object.values(formConfig.fieldNames),
      (name) => form.elements[name].value,
    );
    form.parentElement.querySelector(`.${css.classNames.queryErrorMessage}`)
      .classList
      .toggle(css.classNames.hide, isValid);
    return isValid;
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const { target } = event;
    const submitButton = target.closest('button');
    const form = submitButton.closest('form');
    // Need to run both validations for FormCourseFinder for better user experience
    // eslint-disable-next-line no-bitwise
    const isValidForm = validateCourseFinderForm(form) & validateFormElements(form);

    if (!isValidForm) return;

    addUtmParamsToFormData(form);
    toggleFormDisabledState(form, submitButton);

    const formId = form.id;
    const action = form.getAttribute('action');
    const method = form.getAttribute('method');
    const headers = new Headers({ Accept: 'application/json' });
    const body = new FormData(form);

    if (!form.elements.pageUrl?.value) {
      body.append('pageUrl', window.location.href);
    }

    const displayNotificationMessage = (status) => {
      const isOneTrustId = submitButton.dataset?.id;
      if (status >= 300) {
        toggleSnackbarMessage('bg-error', true);
      } else if (isOneTrustId === '0010') {
        const successMessageWrapper = document.getElementsByClassName('form-success-container')[0];
        const formWrapper = document.getElementsByClassName('form-wrapper')[0];
        const successMessageTitle = document.getElementsByClassName('form-title ')[0];
        toggleSnackbarMessage('', false);
        if (successMessageWrapper) {
          successMessageTitle.innerHTML = submitButton.dataset?.title;
          successMessageWrapper?.classList.remove('invisible');
          formWrapper?.classList.add('hide');
        }
      }
    };

    body.set('subject', body.getAll('subject').filter((string) => string).join(', '));
    fetch(action, {
      method,
      headers,
      body,
    }).then(({ status }) => {
      displayNotificationMessage(status);
      // eslint-disable-next-line no-shadow
      const publishFormSubmitMessage = (status) => publishMessage(messages.formSubmit, { status, formId });
      if (status >= 200 && status < 300) {
        toggleSnackbarMessage('', false);
        publishFormSubmitMessage(statuses.accepted);
      }
    })
      .finally(() => {
        toggleFormDisabledState(form, submitButton, false);
      });
  };

  mount((element) => {
    const dropzone = element.closest('form')?.querySelector(customDropzoneSelector);

    if (dropzone) return;

    const submitButton = element.querySelector('button');
    addEventListener(submitButton, 'click', onSubmit);
  });
});
